// api/AuditsExport.ts
import { Model, Attr, HasOne, Link } from 'spraypaint';
import AuditsExportsAllInvoice from '@spraypaint/audits/exports/AuditsExportsAllInvoice';
import ApplicationRecord from '../ApplicationRecord';

export type StatusEnum =
  | 'created'
  | 'scheduled'
  | 'running'
  | 'success'
  | 'error';

export type ExportedFile = {
  filename: string;
  url: string;
  content_type: string;
  bytesize: number;
  human_size: string;
};

@Model()
class AuditsExport extends ApplicationRecord {
  static jsonapiType = 'audits-exports';

  @Link() download!: string;

  // Attributes
  @Attr({ persist: false }) status!: StatusEnum;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) filename!: string;

  // Extra-attributes
  @Attr({ persist: false }) exportedFiles!: ExportedFile[];

  // Relationships
  @HasOne({ name: 'export_strategy' })
  exportStrategy!: AuditsExportsAllInvoice;
}

export default AuditsExport;
