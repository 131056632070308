import AuditsExportsAllInvoice from '@spraypaint/audits/exports/AuditsExportsAllInvoice';
import { ControlledDateInput } from '@common/components/inputs/controlledInput/ControlledDateInput';
import { useTranslation } from 'react-i18next';
import {
  SubformComponent,
  SubCardFieldsComponent,
  SubModule,
} from '@/modules/admin/audits/components/submodules/types';
import { LineText } from '@common/components/fragments/LineText';

export const jsonApiType = AuditsExportsAllInvoice.jsonapiType;

export type Values = {
  startDate: AuditsExportsAllInvoice['startDate'];
};

export const defaultValues: Values = {
  startDate: '2022-01-01',
};

function createExportStrategy(data: Values): AuditsExportsAllInvoice {
  return new AuditsExportsAllInvoice({
    startDate: data.startDate,
  });
}

export const FormComponent: SubformComponent = ({
  // eslint-disable-line @typescript-eslint/no-explicit-any
  control,
}) => {
  const { t } = useTranslation();
  return (
    <ControlledDateInput
      label={t(
        'activerecord.attributes.audits/exports/all_invoice.start_date',
        {
          ns: 'rails',
        },
      )}
      className="mt-20"
      control={control}
      defaultValue={undefined}
      name="exportStrategy.params.startDate"
      stringMode
      required
    />
  );
};

export const CardFieldsComponent: SubCardFieldsComponent<
  AuditsExportsAllInvoice
> = ({ strategy }) => {
  const { t } = useTranslation();

  return (
    <>
      <LineText
        label={t('activerecord.attributes.audits/export.export_strategy', {
          ns: 'rails',
        })}
        value={t('activerecord.models.audits/exports/all_invoice', {
          ns: 'rails',
        })}
      />{' '}
      <LineText
        label={t(
          'activerecord.attributes.audits/exports/all_invoice.start_date',
          {
            ns: 'rails',
          },
        )}
        value={strategy.startDate}
      />{' '}
    </>
  );
};

export const AllInvoiceSubModule: SubModule<Values, typeof jsonApiType> = {
  jsonApiType,
  defaultValues,
  FormComponent,
  CardFieldsComponent,
  createExportStrategy,
};
