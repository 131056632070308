import type { FunctionComponent } from 'react';
import { PageLayout } from '@common/components/PageLayout';
import { FindQueryOpts } from '@/app/utils/findQueryBuilder';
import { useParams } from 'react-router-dom';
import { useAuditsExportFindQuery } from '@/modules/admin/audits/functions/auditsExportQuery';
import AuditsExport, { ExportedFile } from '@spraypaint/audits/AuditsExport';
import { MainCard } from '@common/components/mainCard';
import { LineText } from '@common/components/fragments/LineText';
import { useTranslation } from 'react-i18next';
import Table from '@common/components/table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import Status from '@common/components/statuses/Status';
import { AuditsExportSubCardFields } from '@/modules/admin/audits/components/AuditsExportSubCardFields';
import { ButtonSecondaryMinus } from '@common/components/buttons';
import { PersistedSpraypaintRecord } from 'spraypaint';

export interface AuditsExportShowProps {}

export const queryOpts: FindQueryOpts = {
  includes: ['export_strategy'],
  select: {},
  selectExtra: {
    [AuditsExport.jsonapiType]: ['exported_files'],
  },
};

interface ExportedFilesTableProps {
  record: PersistedSpraypaintRecord<AuditsExport>;
}

const ch = createColumnHelper<ExportedFile>();
const columns = [
  ch.accessor('filename', { header: 'Nom' }),
  ch.accessor('human_size', { header: 'Taille' }),
];

export const ExportedFilesTable: FunctionComponent<ExportedFilesTableProps> = ({
  record,
}) => {
  const { t } = useTranslation();
  const {
    filename,
    exportedFiles,
    links: { download },
  } = record;

  return (
    <Table
      title={t('activerecord.attributes.audits/export.exported_files', {
        ns: 'rails',
      })}
      tableInformations={
        <div className="py-2">
          <ButtonSecondaryMinus
            href={download}
            className="px-[35px]"
            download={filename}
          >
            {t('default.button.download', { ns: 'rails' })}
          </ButtonSecondaryMinus>
        </div>
      }
      className="col-span-2"
      data={exportedFiles}
      columns={columns}
      totalCount={exportedFiles.length}
      reactTableProps={{ enableColumnFilters: false }}
      onRowClick={(file) => () => window.open(file.url, '_blank')}
    />
  );
};

export const AuditsExportShow: FunctionComponent<
  AuditsExportShowProps
> = () => {
  const { auditsExportId } = useParams();
  const { record, data } = useAuditsExportFindQuery(auditsExportId, queryOpts, {
    staleTime: 30000,
  });

  const { t } = useTranslation();
  if (!record) return null;
  const downloadUrl = data?.data?.links?.download;

  return (
    <PageLayout Title={`Export ${record.id}`}>
      <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
        <MainCard header="Info">
          {' '}
          <LineText
            label={t('activerecord.attributes.audits/export.id', {
              ns: 'rails',
            })}
            value={record.id}
          />{' '}
          <LineText
            label={t('activerecord.attributes.audits/export.created_at', {
              ns: 'rails',
            })}
            value={record.createdAt}
          />{' '}
          <LineText
            label={t('activerecord.attributes.audits/export.updated_at', {
              ns: 'rails',
            })}
            value={record.updatedAt}
          />{' '}
          <LineText
            label={t('activerecord.attributes.audits/export.status', {
              ns: 'rails',
            })}
            value={<Status statusType={record.status} />}
          />{' '}
        </MainCard>
        <MainCard header="Paramétrage">
          <AuditsExportSubCardFields strategy={record.exportStrategy} />
        </MainCard>
        <ExportedFilesTable record={record} />
      </div>
    </PageLayout>
  );
};
