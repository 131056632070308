import type { FunctionComponent } from 'react';
import AuditsExport from '@spraypaint/audits/AuditsExport';
import { getSubModuleByJsonApiType } from '@/modules/admin/audits/components/submodules';

export interface AuditsExportSubCardFieldsProps {
  strategy: AuditsExport['exportStrategy'];
}

export const AuditsExportSubCardFields: FunctionComponent<
  AuditsExportSubCardFieldsProps
> = ({ strategy }) => {
  const jsonApiKey = strategy.jsonapiType;
  const subModule = getSubModuleByJsonApiType(jsonApiKey ?? null);

  if (!subModule) {
    return null;
  }

  const SubCardFieldsComponent = subModule.CardFieldsComponent;
  return <SubCardFieldsComponent strategy={strategy} />;
};
